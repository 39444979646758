import * as React from 'react'
import { graphql } from 'gatsby'
import { Container, Row } from 'react-bootstrap'

import MainLayout from '../../components/main-layout'
import Seo from '../../components/seo'
import HeaderBlock from '../../components/header-block'
import ContentCard from '../../components/card'

import { mainRow } from '../../styles/common.module.css'


const CategoriesPage = ({ data }) => {

    return (
        <MainLayout pageTitle="Categories">

            <Seo title="Categories" description="ai03's Project Portfolio" />

            <Container>
                <HeaderBlock header="Project Categories" />
                <Row xs={1} md={2} className={`g-4 justify-content-center ${mainRow}`}>
                    {
                        data.allStrapiCategory.nodes.map(node => (
                            <ContentCard key={node.slug} cardTitle={node.name} cardDesc={node.description} cardLink={node.slug} cardImg={node.cover.localFile} buttonText="View All"></ContentCard>
                        ))
                    }
                </Row>
            </Container>


        </MainLayout>
    )
}

export const query = graphql`
query {
    allStrapiCategory(sort: {fields: ordering, order: ASC}) {
      nodes {
        id
        name
        description
        slug
        cover {
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.618
                transformOptions: {fit: COVER, cropFocus: CENTER}
                placeholder: BLURRED
                width: 650
                quality: 100
              )
            }
            url
          }
        }
      }
    }
  }
  
`

export default CategoriesPage